<template>
  <div class="upgrade-management" ref="upgradeManagement">
    <div class="form-area" ref="form">
      <el-form :inline="true" :model="form" class="demo-form-inline">
        <el-form-item>
          <el-button
            type="primary"
            size="small"
            @click="onAddUpgrade"
            v-if="$store.state.menu.nowMenuList.indexOf('新增') >= 0"
            >新增</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="
        tableData.slice(
          (form.currentPage - 1) * form.pageSize,
          form.currentPage * form.pageSize
        )
      "
      stripe
      :height="tableHeight"
    >
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="name" label="文件名称"></el-table-column>
      <el-table-column prop="producerName" label="厂家名称">
        <template slot-scope="scope">
          {{ producerObj[Number(scope.row.makerId)] }}
        </template>
      </el-table-column>
      <el-table-column
        prop="path"
        label="升级包路径"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column prop="version" label="版本号"> </el-table-column>
      <el-table-column
        prop="createTime"
        :width="180 * this.$store.state.vehicle.screenCoefficient"
        label="创建时间"
      >
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            @click="onDelUpgrade(scope.row)"
            type="text"
            size="small"
            v-if="$store.state.menu.nowMenuList.indexOf('删除') >= 0"
            sort="primary"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <!-- 新增终端类型 -->
    <el-dialog
      title="添加升级包"
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <add-upgrade
        ref="addUpgrade"
        @close="onDialogClose"
        @onLoading="onLoading"
      ></add-upgrade>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="onSave()"
          :loading="editLoading"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { formatDict } from '@/common/utils/index'
import {
  queryUpgradeList,
  queryDictsByCodes,
  delUpgrade
} from '@/api/lib/api.js'
import addUpgrade from './components/addUpgrade.vue'

export default {
  name: 'upgradeManagement',
  components: { addUpgrade },
  data() {
    return {
      tableHeight: 0,
      total: 0,
      tableData: [],
      form: {
        currentPage: 1,
        pageSize: 10
      },
      producerObj: null,
      title: '',
      dialogVisible: false,
      editLoading: false,
      searchLoading: false
    }
  },
  methods: {
    // 计算表格高度
    computeHeight() {
      const wholeHeight = this.$refs.upgradeManagement.clientHeight
      const formHeight = this.$refs.form.clientHeight
      const paginationHeight = this.$refs.pagination.clientHeight
      this.tableHeight =
        wholeHeight - 16 - formHeight - paginationHeight - 10 - 16
    },
    // 点击查询
    onSearch() {
      this.searchLoading = true
      this.form.currentPage = 1
      this.form.pageSize = 10
      this.getListByField()
    },
    //获取数据
    getListByField() {
      queryUpgradeList()
        .then((res) => {
          if (res.code === 1000) {
            this.tableData = res.data
            this.total = res.data.length
          } else {
            this.tableData = []
            this.total = 0
          }
          this.searchLoading = false
        })
        .catch(() => {
          this.searchLoading = false
        })
    },
    // 当前页码改变
    onCurrentChange(page) {
      this.form.currentPage = page
    },

    // 当前条数改变
    onSizeChange(size) {
      this.form.pageSize = size
    },
    // 新增
    onAddUpgrade() {
      this.dialogVisible = true
    },
    // 删除
    onDelUpgrade(row) {
      const h = this.$createElement
      this.$msgbox({
        title: '消息',
        type: 'warning',
        message: h('p', null, [h('span', null, '确定删除这个升级包吗 ？')]),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          delUpgrade(row.id).then((res) => {
            if (res.code === 1000) {
              this.$message.success('删除成功')
              this.getListByField()
            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            showClose: true,
            message: '已取消删除'
          })
        })
    },
    //  保存
    onSave() {
      this.$refs.addUpgrade.onSave()
    },
    onLoading(flag) {
      this.editLoading = flag
    },
    // 弹窗关闭时的回调
    onDialogClose(flag) {
      this.dialogVisible = false
      if (flag) {
        this.onSearch()
      }
      this.editLoading = false
    },
    //获取字典值
    getDicts() {
      queryDictsByCodes({ parentCodes: 'SBCJ' }).then((res) => {
        if (res.code === 1000) {
          this.producerObj = formatDict(res.data.SBCJ)
        }
      })
    }
  },
  created() {
    this.getDicts()
  },
  mounted() {
    this.$nextTick(() => {
      this.computeHeight()
      this.onSearch()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  destroyed() {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.upgrade-management {
  height: 100%;
  width: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.theme-project-resourse {
  .upgrade-management {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
